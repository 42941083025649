/* Colors */
/* Typography */
/* Spacing */
.wrapper {
  position: relative;
  width: 100%;
  display: block; }

.label {
  font-size: 11px;
  text-align: left;
  font-weight: normal;
  color: #b6b6b6;
  opacity: 0;
  z-index: 1;
  top: 10px;
  position: absolute;
  width: 100%;
  padding: 0 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transform: translateY(3px);
       -o-transform: translateY(3px);
          transform: translateY(3px);
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out, -o-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out, -webkit-transform 0.2s ease-out, -o-transform 0.2s ease-out; }

.textarea {
  border: 1px solid #e0e0e0;
  padding: 10px 12px;
  width: 100%;
  background-color: #fff;
  display: inline-block;
  min-height: 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  resize: none;
  font-size: 16px;
  line-height: 20px;
  font-family: inherit;
  -webkit-transition: opacity 0.2s ease-out, padding 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out, padding 0.2s ease-out;
  transition: opacity 0.2s ease-out, padding 0.2s ease-out;
  overflow: auto;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none; }
  @media only screen and (min-width: 801px) {
    .textarea {
      font-size: 14px; } }
  .textarea:focus {
    outline: none;
    border-color: #b6b6b6;
    -webkit-box-shadow: 0 0 1px 1px #b6b6b6;
            box-shadow: 0 0 1px 1px #b6b6b6; }
  .textarea::-webkit-input-placeholder {
    color: #999999; }
  .textarea::-moz-placeholder {
    color: #999999; }
  .textarea::placeholder {
    color: #999999; }

.floatingLabel .label {
  -webkit-transform: none;
       -o-transform: none;
          transform: none;
  opacity: 1; }

.floatingLabel .textarea {
  padding-top: 22px;
  padding-bottom: 6px; }
  .floatingLabel .textarea::-webkit-input-placeholder {
    color: transparent; }
  .floatingLabel .textarea::-moz-placeholder {
    color: transparent; }
  .floatingLabel .textarea::placeholder {
    color: transparent; }
