/* Colors */
/* Typography */
/* Spacing */
.wrapper {
  max-width: 700px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.points {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #fafafa;
  margin-bottom: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.checkItem {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 12px;
  padding: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media only screen and (min-width: 801px) {
    .checkItem {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; } }

.inner {
  padding: 12px 12px 26px 12px; }

.bold {
  font-weight: 600;
  line-height: 1.4; }

.labelBig {
  text-transform: uppercase;
  margin-right: 6px; }

.labelSmall {
  margin-right: 6px; }

.block {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .block:first-child {
    margin-bottom: 12px; }
  @media only screen and (min-width: 801px) {
    .block:last-child {
      text-align: right; } }
  @media only screen and (min-width: 801px) {
    .block {
      width: 50%; }
      .block:first-child {
        margin-bottom: 0; } }

.detail {
  display: block; }
