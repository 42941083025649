/* Colors */
/* Typography */
/* Spacing */
.message {
  font-size: 11px;
  left: 12px;
  padding: 0;
  margin: 4px 0;
  display: block;
  white-space: pre-line; }

.error {
  color: #f45b4f; }
