/* Colors */
/* Typography */
/* Spacing */
.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.title {
  margin: 0;
  font-size: 19px;
  font-weight: 400; }

.accent {
  color: #0027f4; }

.light {
  color: #525252; }
