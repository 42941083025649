/* Colors */
/* Typography */
/* Spacing */
.section {
  padding-bottom: 20px; }

.half {
  padding-bottom: 10px; }

.clear {
  padding: 0; }
