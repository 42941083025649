/* Colors */
/* Typography */
/* Spacing */
.column {
  padding-top: 12px;
  width: 100%; }
  @media only screen and (min-width: 801px) {
    .column::after {
      content: '';
      background: #fafafa;
      height: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      position: fixed;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      bottom: 0;
      z-index: -1; } }

.note,
.note * {
  color: rgba(0, 0, 0, 0.67);
  font-size: 12px;
  line-height: 1.4;
  font-style: italic;
  text-align: center; }

.header {
  padding-bottom: 34px; }

@-webkit-keyframes show {
  from {
    bottom: -150px; }
  to {
    bottom: 0; } }

@-o-keyframes show {
  from {
    bottom: -150px; }
  to {
    bottom: 0; } }

@keyframes show {
  from {
    bottom: -150px; }
  to {
    bottom: 0; } }
