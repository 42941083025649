/* Colors */
/* Typography */
/* Spacing */
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 200;
  overflow-y: scroll; }

.content {
  padding: 12px 12px 100px 12px;
  text-align: left; }

.button {
  padding: 6px 0; }

.image {
  padding: 24px 0;
  width: 60%;
  margin: 0 auto; }

.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.description {
  margin-top: 12px; }
  .description * {
    font-size: 14px; }
  .description p {
    line-height: 1.4; }

.price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .priceValue {
    font-size: 23px;
    line-height: 1.4; }

.beforeDiscount {
  color: #666666;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  place-self: flex-end; }
  .beforeDiscount::before {
    border-top: 1px solid;
    border-color: #666666;
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
       transform: rotate(0);
    position: absolute;
    top: 50%;
    content: "";
    width: 100%; }

.quantity {
  display: block;
  padding: 0 22px; }

.buttonsWrapper {
  -webkit-box-shadow: 0 0 6px #fafafa;
          box-shadow: 0 0 6px #fafafa;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #fff; }

.buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 14px; }
  .buttons button {
    border: 1px solid #0027f4; }

.quantityButtons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.removeButton {
  position: relative; }
