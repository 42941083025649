/* Colors */
/* Typography */
/* Spacing */
.header {
  text-align: center;
  padding: 48px;
  background: #fafafa; }

.tabs {
  max-width: 630px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  will-change: scroll-position; }
  @media only screen and (min-width: 801px) {
    .tabs {
      max-width: 630px; } }

.tab {
  cursor: pointer;
  text-align: center;
  border-top: 2px solid transparent;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 4px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 37%;
      -ms-flex: 0 0 37%;
          flex: 0 0 37%;
  max-width: 37%; }
  @media only screen and (min-width: 801px) {
    .tab {
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 100%;
          -ms-flex: 0 1 100%;
              flex: 0 1 100%;
      max-width: auto; } }
  .tab span {
    padding: 16px 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }

.selectedTab,
.tab:hover {
  border-top: 2px solid #0027f4; }
