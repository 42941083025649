/* Colors */
/* Typography */
/* Spacing */
.productContainer {
  min-height: 273px; }

.buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 40px; }
  @media only screen and (min-width: 420px) {
    .buttons {
      margin-top: 6px; } }
  .buttons .quantity {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    .buttons .quantity .value {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-self: center;
          -ms-flex-item-align: center;
              align-self: center;
      font-size: 14px;
      margin: 0 12px; }
  .buttons .remove {
    margin-left: 24px; }
    .buttons .remove .removeButton {
      background: transparent;
      display: block;
      border: none;
      font-size: 14px;
      -webkit-box-shadow: none;
              box-shadow: none;
      font-weight: 300;
      cursor: pointer;
      text-align: left;
      padding: 0; }
      .buttons .remove .removeButton:hover, .buttons .remove .removeButton:active, .buttons .remove .removeButton:focus {
        outline: none; }

.imageContainer {
  position: relative;
  min-height: 180px; }
  @media only screen and (min-width: 420px) {
    .imageContainer {
      min-height: 276px; } }

.sizePickerContainer {
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  width: 100%;
  bottom: 21px; }

.wrapper {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: calc(100% - 20px);
  margin: 0 auto; }
  .wrapper .itemTable {
    position: relative;
    display: inline-block;
    width: 20%;
    padding: 0 5px;
    text-align: center; }
    .wrapper .itemTable:first-child {
      padding-left: 0; }
    .wrapper .itemTable:last-child {
      padding-right: 0; }
  .wrapper .item {
    position: relative;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 100%;
        -ms-flex: 0 1 100%;
            flex: 0 1 100%;
    text-align: center;
    margin: 3px;
    padding: 3px;
    background: #fff;
    cursor: pointer;
    border: 1px solid #fff;
    min-height: 18px; }
    .wrapper .item:hover .message {
      display: block; }
    .wrapper .item * {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .wrapper .item:first-child {
      margin-left: 0; }
    .wrapper .item:last-child {
      margin-right: 0; }
  @media only screen and (min-width: 420px) {
    .wrapper :hover {
      background: #222;
      border: 1px solid #222;
      color: #fff; } }
  .wrapper .selectedSize {
    border: 1px solid #000000; }
  .wrapper .missingSelectedSize {
    border: 1px solid #f3725b; }

.loading {
  background-color: #656565;
  pointer-events: none; }

.disabledSize {
  background-color: #f5f5f5;
  color: #c8c8c8;
  -webkit-box-shadow: #f5f5f5 0 0 0 1px;
          box-shadow: #f5f5f5 0 0 0 1px;
  cursor: not-allowed; }

.price {
  font-size: 14px;
  line-height: 1.4; }

.discountedPrice {
  color: #f3725b; }

.priceBeforeDiscount {
  text-decoration: line-through;
  color: #666666; }

.addToCartButtonContainer button {
  color: white;
  min-width: 50%;
  height: 15px;
  width: 80%;
  padding: 12px 6px; }

.productName {
  min-height: 40px;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
