/* Colors */
/* Typography */
/* Spacing */
.fields {
  margin: -10px -10px 10px -10px; }

.message {
  padding-top: 12px; }

.openInfo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  background-color: #f5f4f4;
  padding: 14px;
  margin-top: 10px;
  font-size: 14px; }
  .openInfo::before {
    content: '';
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    margin-right: 12px;
    background-image: url("./../../../img/clock.png");
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    -webkit-background-size: contain;
            background-size: contain; }

.disabledButtonWrapper {
  margin-top: 20px; }
