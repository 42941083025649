/* Colors */
/* Typography */
/* Spacing */
.panel {
  width: auto;
  -webkit-box-sizing: initial;
          box-sizing: initial;
  padding: 48px 22px;
  -webkit-transition-duration: 300ms;
       -o-transition-duration: 300ms;
          transition-duration: 300ms;
  -webkit-transition-property: opacity, -webkit-box-flex, -webkit-flex, -webkit-filter;
  transition-property: opacity, -webkit-box-flex, -webkit-flex, -webkit-filter;
  -o-transition-property: opacity, flex, filter;
  transition-property: opacity, flex, filter;
  transition-property: opacity, flex, filter, -webkit-box-flex, -webkit-flex, -ms-flex, -webkit-filter;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
       -o-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }
  @media only screen and (min-width: 801px) {
    .panel {
      padding: 48px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 32%;
          -ms-flex: 0 1 32%;
              flex: 0 1 32%; } }
  .panel .content,
  .panel .children {
    -webkit-box-sizing: initial;
            box-sizing: initial; }

.active {
  background: #fafafa; }
  @media only screen and (min-width: 801px) {
    .active {
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 50%;
          -ms-flex: 0 1 50%;
              flex: 0 1 50%; } }

.loading {
  opacity: 0.7;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  pointer-events: none; }
