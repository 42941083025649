/* Colors */
/* Typography */
/* Spacing */
.content {
  display: block;
  width: 100%; }

.title {
  font-weight: 600;
  margin-bottom: 12px;
  font-size: 20px; }

.receiptTitle {
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  line-height: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #b6b6b6;
  color: rgba(0, 0, 0, 0.87); }

.items {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0; }
