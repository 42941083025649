.overlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  left: 0;
  z-index: 56;
  position: fixed;
  width: 100vw;
  height: 100vh;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  will-change: opacity;
  -webkit-transition-duration: 350ms;
       -o-transition-duration: 350ms;
          transition-duration: 350ms;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
       -o-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }
  .overlay .background {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
    z-index: 0; }
  .overlay .content {
    background: #fff;
    -webkit-box-shadow: 0 0 8px #717171;
            box-shadow: 0 0 8px #717171;
    border-radius: 4px;
    height: 100%;
    z-index: 2;
    margin: 0 auto;
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%; }
    @media only screen and (min-width: 801px) {
      .overlay .content {
        height: auto;
        width: auto; } }
  .overlay .close {
    position: absolute;
    top: 24px;
    right: 24px; }

:global(.fade-enter) {
  opacity: 0; }

:global(.fade-enter-active) {
  opacity: 1; }

:global(.fade-exit) {
  opacity: 1;
  position: fixed; }

:global(.fade-exit-active) {
  opacity: 0; }
