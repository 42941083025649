.forms {
  max-width: 400px;
  margin: 0 auto; }

@media only screen and (min-width: 801px) {
  .info {
    width: 60%; } }

.info .text {
  padding: 12px; }
