/* Colors */
/* Typography */
/* Spacing */
.formContent {
  position: relative; }

.price {
  padding: 4px 0;
  width: 100%;
  text-align: center;
  font-size: 13px; }

.buttonWrapper {
  padding-bottom: 100px;
  margin-top: 32px; }

.readOnly {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  pointer-events: none;
  opacity: 0.6; }
