/* Colors */
/* Typography */
/* Spacing */
.steps {
  width: 100%;
  padding-top: 0;
  background: #fafafa; }
  @media only screen and (min-width: 801px) {
    .steps {
      padding-top: 30px;
      background: transparent; } }
