/* Colors */
/* Typography */
/* Spacing */
.form {
  max-width: 320px;
  margin: 0 auto;
  padding: 12px 12px 26px 12px; }

.fields {
  margin: -10px; }

.wrapper {
  max-width: 480px;
  margin: 0 auto;
  position: relative; }

.button {
  margin-top: 20px; }
