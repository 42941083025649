/* Colors */
/* Typography */
/* Spacing */
.container {
  background: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 4px; }
  .container > div {
    border-bottom: 1px solid #e0e0e0; }
    .container > div:last-child {
      border-bottom: none; }
  .container.column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border: none;
    border-radius: 0;
    overflow-x: auto;
    overflow-y: hidden;
    margin: -6px;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (min-width: 801px) {
      .container.column {
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; } }
