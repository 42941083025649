/* Colors */
/* Typography */
/* Spacing */
.wrapper.inline {
  display: inline-block;
  padding-left: 4px; }

.wrapper.block {
  display: block; }

.link {
  color: #0027f4;
  text-decoration: underline;
  font-size: 13px; }
