/* Colors */
/* Typography */
.loadMore {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4; }

/* Spacing */
.wrapper {
  max-width: 700px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 48px; }

.loadMore {
  text-align: center;
  padding: 16px;
  border: none;
  background: none;
  cursor: pointer;
  margin: 0 8px 8px; }
  @media only screen and (min-width: 420px) {
    .loadMore {
      margin: 0 16px 16px; } }
  .loadMore:hover, .loadMore:active {
    background-color: #fafafa; }
