/* Colors */
/* Typography */
/* Spacing */
.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media only screen and (min-width: 801px) {
    .wrapper {
      width: auto; } }
  .wrapper .iconInside {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center; }
  .wrapper .iconOutside {
    width: 13px;
    height: 13px;
    margin-right: 6px; }
  .wrapper.iconRight {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
    .wrapper.iconRight.right {
      -webkit-box-pack: right;
      -webkit-justify-content: right;
          -ms-flex-pack: right;
              justify-content: right; }
    .wrapper.iconRight .iconOutside {
      margin-left: 6px;
      margin-right: 0; }

.inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .innerNotVisible {
    visibility: hidden; }

.button {
  display: block;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 400;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  text-align: left; }
  .button:hover, .button:active, .button:focus {
    outline: none;
    opacity: 0.9; }

.outline {
  font-size: 13px;
  padding: 6px 18px;
  background-color: transparent;
  border: solid 1px #000;
  color: #000;
  text-align: center;
  border-radius: 4px; }

.center {
  margin: 0 auto; }

.big {
  padding: 24px;
  width: 100%;
  min-width: 290px; }
  .big.low {
    padding: 14px 24px; }
  .big.clear {
    padding: 24px; }
  .big.short {
    min-width: 240px; }
  @media only screen and (min-width: 801px) {
    .big {
      width: auto; }
      .big.short {
        min-width: 240px; } }

.long {
  width: 70%; }

.medium {
  padding: 15px 35px; }

.left {
  margin: 0; }

.right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.primary {
  font-size: 14px;
  background-color: #0027f4;
  color: #fff;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); }
  .primary.low {
    padding: 14px 24px; }

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0.2; }
  20% {
    -webkit-transform: scale(50, 50);
            transform: scale(50, 50);
    opacity: 0.1; }
  100% {
    opacity: 0;
    -webkit-transform: scale(80, 80);
            transform: scale(80, 80); } }

@-o-keyframes ripple {
  0% {
    -o-transform: scale(0, 0);
       transform: scale(0, 0);
    opacity: 0.2; }
  20% {
    -o-transform: scale(50, 50);
       transform: scale(50, 50);
    opacity: 0.1; }
  100% {
    opacity: 0;
    -o-transform: scale(80, 80);
       transform: scale(80, 80); } }

@keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
         -o-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0.2; }
  20% {
    -webkit-transform: scale(50, 50);
         -o-transform: scale(50, 50);
            transform: scale(50, 50);
    opacity: 0.1; }
  100% {
    opacity: 0;
    -webkit-transform: scale(80, 80);
         -o-transform: scale(80, 80);
            transform: scale(80, 80); } }
  .primary::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 50%;
    -webkit-transform: scale(1, 1) translate(-50%);
         -o-transform: scale(1, 1) translate(-50%);
            transform: scale(1, 1) translate(-50%);
    -webkit-transform-origin: 50% 50%;
         -o-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  .primary:focus:not(:active)::after {
    -webkit-animation: ripple 1.5s ease-out;
         -o-animation: ripple 1.5s ease-out;
            animation: ripple 1.5s ease-out; }

.small {
  padding: 6px 18px; }

.low {
  padding: 14px 24px; }

.clear {
  padding: 0;
  border: none;
  font-size: 16px;
  color: #0027f4;
  background-color: transparent; }

.disabled {
  pointer-events: none;
  background-color: #f2f0e6;
  color: #999999;
  -webkit-box-shadow: none;
          box-shadow: none; }
