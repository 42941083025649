/* Colors */
/* Typography */
/* Spacing */
.section {
  padding: 14px 0;
  margin: 0 12px;
  position: relative;
  background-color: #fff; }
  @media only screen and (min-width: 801px) {
    .section {
      padding: 20px 0;
      margin: 0 24px; } }

.borderBottom {
  border-bottom: 1px solid #e0e0e0; }

.transparent {
  background-color: transparent; }

.content {
  display: block; }
