/* Colors */
/* Typography */
/* Spacing */
.module {
  background-color: #f5f5f5;
  padding: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

.label {
  line-height: 1.4;
  padding-right: 12px;
  margin: 0; }

.name {
  line-height: 1.4;
  padding-right: 12px;
  border-right: 1px solid #a3a3a3;
  margin-right: 12px;
  color: #0027f4;
  text-decoration: underline; }

.logout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

.groupTitle {
  color: #0027f4;
  font-size: 19px;
  padding: 12px 0; }

.spinner {
  position: absolute;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
