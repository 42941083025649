/* Colors */
/* Typography */
.title {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4; }

/* Spacing */
.title {
  padding: 8px 0; }

.accent {
  color: #0027f4; }

.big {
  font-size: 19px;
  padding: 12px 0; }
