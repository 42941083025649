.list {
  display: block; }

.loadLessButton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 22px 0; }

.labels {
  font-size: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  margin: 20px 0;
  padding: 8px 0; }

.imageLabel {
  padding-right: 20px;
  width: 100px; }

.attributes {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  text-align: left; }

.leftLabel {
  width: 50%; }

.rightLabel {
  width: 50%;
  text-align: right; }

:global(.react-slidedown.slidedown) {
  -webkit-transition-duration: 0.4s;
       -o-transition-duration: 0.4s;
          transition-duration: 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
       -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
