@charset "UTF-8";
/* Colors */
/* Typography */
/* Spacing */
.wrapper {
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }

.wrapperAcceptToEnter {
  height: 100vh;
  z-index: 1200;
  position: fixed; }
  @media only screen and (min-width: 801px) {
    .wrapperAcceptToEnter {
      position: absolute;
      padding-right: 10%;
      padding-top: 16px;
      width: auto; } }

.iconWrapper {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  right: 0;
  top: -18px;
  padding: 8px 10px;
  background: #fff;
  border-radius: 24px 0 0 24px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  z-index: 23; }
  .iconWrapper button {
    width: 20px;
    height: 20px; }
  @media only screen and (min-width: 801px) {
    .iconWrapper {
      top: 18px; } }

.headerWrapper {
  display: block; }

.header {
  color: #0027f4;
  font-size: 19px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 14px; }
  .header .headerText {
    line-height: 1; }
  .header::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("./../../../img/icon-info.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    -webkit-background-size: 20px 20px;
            background-size: 20px 20px;
    margin-right: 10px; }

.text {
  padding: 14px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  min-height: 0; }
  @media only screen and (min-width: 801px) {
    .text {
      height: auto;
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
              flex: 0 1 auto; } }
  .text ul {
    margin: 0;
    padding: 0 18px;
    list-style: none; }
    .text ul li {
      line-height: 1.4;
      margin-bottom: 10px; }
      .text ul li::before {
        content: '•';
        color: #0027f4;
        display: inline-block;
        width: 16px;
        margin-left: -16px; }

.buttonsWrapper {
  -webkit-box-shadow: 0 0 6px #fafafa;
          box-shadow: 0 0 6px #fafafa;
  width: 100%;
  background: #fff; }
  @media only screen and (min-width: 801px) {
    .buttonsWrapper {
      margin-top: 70px;
      -webkit-box-shadow: none;
              box-shadow: none;
      position: relative;
      left: auto;
      bottom: auto;
      right: auto;
      width: 100%;
      background: transparent; } }
  .buttonsWrapper .buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 14px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%; }
    @media only screen and (min-width: 801px) {
      .buttonsWrapper .buttons {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
            -ms-flex-direction: row;
                flex-direction: row;
        padding: 0; } }
