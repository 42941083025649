/* Colors */
/* Typography */
/* Spacing */
.fieldset {
  margin: -10px;
  padding-bottom: 12px; }
  .fieldset::before, .fieldset::after {
    content: '';
    display: table; }
  .fieldset::after {
    clear: both; }

.addressReadOnly {
  display: block;
  margin: 0;
  padding-bottom: 0;
  width: 80%; }
  @media only screen and (min-width: 801px) {
    .addressReadOnly {
      width: 60%; } }
