.header {
  padding-bottom: 24px;
  text-align: center; }

.title {
  font-size: 20px;
  line-height: 1.4; }

.subTitle {
  font-size: 14px;
  line-height: 1.4; }
