/* Colors */
/* Typography */
.line {
  font-size: 11px;
  color: #666666;
  line-height: 1.4; }

/* Spacing */
.item {
  margin-bottom: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .item:last-child {
    margin-bottom: 0; }

.inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.2s;
       -o-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
       -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.faded {
  opacity: 0.1;
  pointer-events: none; }

.moreButton {
  position: absolute;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  z-index: 2;
  width: 100%;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.detailsContainer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  text-align: left; }
  @media only screen and (max-width: 320px) {
    .detailsContainer {
      width: 76%; } }

.details {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  text-align: left; }
  @media only screen and (max-width: 320px) {
    .details {
      width: 76%; } }

.attributes {
  margin-top: 6px; }

.image {
  padding-right: 20px;
  width: 100px; }

.left {
  width: 50%; }

.leftGift {
  width: 100%; }

.right {
  width: 50%;
  max-width: 136px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end; }

.buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 6px;
  margin-left: -6px; }
  .buttons .quantity {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    .buttons .quantity .value {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-self: center;
          -ms-flex-item-align: center;
              align-self: center;
      font-size: 14px;
      margin: 0 12px; }
  .buttons .remove {
    margin-left: 24px; }
    .buttons .remove .removeButton {
      background: transparent;
      display: block;
      border: none;
      font-size: 14px;
      -webkit-box-shadow: none;
              box-shadow: none;
      font-weight: 300;
      cursor: pointer;
      text-align: left;
      padding: 0; }
      .buttons .remove .removeButton:hover, .buttons .remove .removeButton:active, .buttons .remove .removeButton:focus {
        outline: none; }

.price {
  text-align: right;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 100%; }

.priceHided {
  visibility: hidden;
  opacity: 0; }

.spinner {
  position: absolute;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  min-width: 50%;
  display: none; }

.spinnerVisible {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.line {
  color: #666666; }

.giftWrap {
  background: #f5f5f5;
  padding: 12px;
  border-radius: 8px; }

.ageLimitLabel {
  padding: 4px 6px;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 10px;
  color: #fff;
  background: #4eb7f0;
  text-transform: uppercase;
  margin-top: 12px; }

.customTextsContainer {
  width: 61%; }
  @media only screen and (min-width: 1024px) {
    .customTextsContainer {
      width: 50%; } }

.customTextInput {
  padding: 3px 0; }
  .customTextInput input {
    text-transform: uppercase; }

.bundle a,
.bundleProduct a {
  pointer-events: none; }
