.spinner {
  position: relative;
  width: 20px;
  height: 20px; }
  .spinner__item {
    position: absolute;
    left: 44.5%;
    top: 37%;
    width: 10%;
    height: 25%;
    border-radius: 50%/20%;
    -webkit-animation: SpinnerAnimation 1s linear infinite;
         -o-animation: SpinnerAnimation 1s linear infinite;
            animation: SpinnerAnimation 1s linear infinite;
    -webkit-animation-play-state: paused;
         -o-animation-play-state: paused;
            animation-play-state: paused;
    background-color: #8c8c8c; }
    .spinner__item:nth-child(1) {
      -webkit-animation-delay: -1.6666666667s;
           -o-animation-delay: -1.6666666667s;
              animation-delay: -1.6666666667s;
      -webkit-transform: rotate(30deg) translate(0, -150%);
           -o-transform: rotate(30deg) translate(0, -150%);
              transform: rotate(30deg) translate(0, -150%); }
    .spinner__item:nth-child(2) {
      -webkit-animation-delay: -1.5833333333s;
           -o-animation-delay: -1.5833333333s;
              animation-delay: -1.5833333333s;
      -webkit-transform: rotate(60deg) translate(0, -150%);
           -o-transform: rotate(60deg) translate(0, -150%);
              transform: rotate(60deg) translate(0, -150%); }
    .spinner__item:nth-child(3) {
      -webkit-animation-delay: -1.5s;
           -o-animation-delay: -1.5s;
              animation-delay: -1.5s;
      -webkit-transform: rotate(90deg) translate(0, -150%);
           -o-transform: rotate(90deg) translate(0, -150%);
              transform: rotate(90deg) translate(0, -150%); }
    .spinner__item:nth-child(4) {
      -webkit-animation-delay: -1.4166666667s;
           -o-animation-delay: -1.4166666667s;
              animation-delay: -1.4166666667s;
      -webkit-transform: rotate(120deg) translate(0, -150%);
           -o-transform: rotate(120deg) translate(0, -150%);
              transform: rotate(120deg) translate(0, -150%); }
    .spinner__item:nth-child(5) {
      -webkit-animation-delay: -1.3333333333s;
           -o-animation-delay: -1.3333333333s;
              animation-delay: -1.3333333333s;
      -webkit-transform: rotate(150deg) translate(0, -150%);
           -o-transform: rotate(150deg) translate(0, -150%);
              transform: rotate(150deg) translate(0, -150%); }
    .spinner__item:nth-child(6) {
      -webkit-animation-delay: -1.25s;
           -o-animation-delay: -1.25s;
              animation-delay: -1.25s;
      -webkit-transform: rotate(180deg) translate(0, -150%);
           -o-transform: rotate(180deg) translate(0, -150%);
              transform: rotate(180deg) translate(0, -150%); }
    .spinner__item:nth-child(7) {
      -webkit-animation-delay: -1.1666666667s;
           -o-animation-delay: -1.1666666667s;
              animation-delay: -1.1666666667s;
      -webkit-transform: rotate(210deg) translate(0, -150%);
           -o-transform: rotate(210deg) translate(0, -150%);
              transform: rotate(210deg) translate(0, -150%); }
    .spinner__item:nth-child(8) {
      -webkit-animation-delay: -1.0833333333s;
           -o-animation-delay: -1.0833333333s;
              animation-delay: -1.0833333333s;
      -webkit-transform: rotate(240deg) translate(0, -150%);
           -o-transform: rotate(240deg) translate(0, -150%);
              transform: rotate(240deg) translate(0, -150%); }
    .spinner__item:nth-child(9) {
      -webkit-animation-delay: -1s;
           -o-animation-delay: -1s;
              animation-delay: -1s;
      -webkit-transform: rotate(270deg) translate(0, -150%);
           -o-transform: rotate(270deg) translate(0, -150%);
              transform: rotate(270deg) translate(0, -150%); }
    .spinner__item:nth-child(10) {
      -webkit-animation-delay: -0.9166666667s;
           -o-animation-delay: -0.9166666667s;
              animation-delay: -0.9166666667s;
      -webkit-transform: rotate(300deg) translate(0, -150%);
           -o-transform: rotate(300deg) translate(0, -150%);
              transform: rotate(300deg) translate(0, -150%); }
    .spinner__item:nth-child(11) {
      -webkit-animation-delay: -0.8333333333s;
           -o-animation-delay: -0.8333333333s;
              animation-delay: -0.8333333333s;
      -webkit-transform: rotate(330deg) translate(0, -150%);
           -o-transform: rotate(330deg) translate(0, -150%);
              transform: rotate(330deg) translate(0, -150%); }
    .spinner__item:nth-child(12) {
      -webkit-animation-delay: -0.75s;
           -o-animation-delay: -0.75s;
              animation-delay: -0.75s;
      -webkit-transform: rotate(360deg) translate(0, -150%);
           -o-transform: rotate(360deg) translate(0, -150%);
              transform: rotate(360deg) translate(0, -150%); }

.spinner.animating .spinner__item {
  -webkit-animation-play-state: running;
       -o-animation-play-state: running;
          animation-play-state: running;
  will-change: opacity; }

@-webkit-keyframes SpinnerAnimation {
  0% {
    opacity: 0.85; }
  50% {
    opacity: 0.25; }
  100% {
    opacity: 0.25; } }

@-o-keyframes SpinnerAnimation {
  0% {
    opacity: 0.85; }
  50% {
    opacity: 0.25; }
  100% {
    opacity: 0.25; } }

@keyframes SpinnerAnimation {
  0% {
    opacity: 0.85; }
  50% {
    opacity: 0.25; }
  100% {
    opacity: 0.25; } }
