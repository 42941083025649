/* Colors */
/* Typography */
/* Spacing */
.content {
  padding: 20px 0 0; }

.step {
  margin: 0;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  background: white;
  -webkit-box-shadow: 0 -3px 2px rgba(0, 0, 0, 0.04);
          box-shadow: 0 -3px 2px rgba(0, 0, 0, 0.04);
  border-radius: 16px 16px 0 0; }
  @media only screen and (min-width: 801px) {
    .step {
      margin: 0 12px;
      padding: 20px 0;
      -webkit-box-shadow: none;
              box-shadow: none;
      background: white;
      border-radius: 0; }
      .step:first-child {
        padding-top: 0;
        border: none; } }

.stepMessage {
  margin: 0;
  font-size: 14px; }

.editButton {
  display: none; }
  @media only screen and (min-width: 801px) {
    .editButton {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; } }

.edit {
  position: relative; }

.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.completed {
  background: rgba(255, 255, 255, 0.8); }
  @media only screen and (min-width: 801px) {
    .completed {
      background: transparent; } }
  .completed .header {
    cursor: pointer;
    pointer-events: all; }

.readOnly {
  padding: 4px 0 0 37px;
  color: #666666;
  font-size: 13px;
  pointer-events: none;
  line-height: 1.4; }

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 12px 0; }
