/* Colors */
/* Typography */
.item {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4; }

/* Spacing */
.item {
  display: block;
  background-color: #fafafa;
  margin: 0 8px 8px;
  line-height: 24px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  text-transform: capitalize; }
  @media only screen and (min-width: 420px) {
    .item {
      margin: 0 16px 16px; } }

.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #f5f5f5;
  padding: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer; }

.order {
  margin-left: auto;
  color: #999999; }
  .order::before {
    content: '#'; }

.body {
  padding: 10px 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }

.description {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px; }

.date {
  color: #0027f4; }

.status {
  display: inline-block;
  width: 50%;
  text-align: left;
  color: #999999; }

.price {
  display: inline-block;
  width: 50%;
  text-align: right; }

.showMore {
  width: 16px;
  height: 16px;
  background-image: url("./../../../img/icon-arrow-down.svg"), none;
  -webkit-background-size: contain;
          background-size: contain;
  margin-right: 4px;
  margin-left: 16px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  .expanded .showMore {
    -webkit-transform: scaleY(-1);
         -o-transform: scaleY(-1);
            transform: scaleY(-1); }

.summary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  opacity: 1;
  -webkit-animation: fadein 0.2s ease-in-out;
       -o-animation: fadein 0.2s ease-in-out;
          animation: fadein 0.2s ease-in-out; }
  @media only screen and (min-width: 801px) {
    .summary {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; } }

.notes {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%; }
  @media only screen and (min-width: 801px) {
    .notes {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 35%;
          -ms-flex: 0 0 35%;
              flex: 0 0 35%; } }
  .notes > small {
    color: #999999; }
  .notes .statusDescription {
    font-size: 13px;
    line-height: 1.4; }
  .notes .details {
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 1.4; }
    .notes .details > p {
      margin: 0; }

@-webkit-keyframes fadein {
  from {
    opacity: 0; } }

@-o-keyframes fadein {
  from {
    opacity: 0; } }

@keyframes fadein {
  from {
    opacity: 0; } }
