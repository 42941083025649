/* Colors */
/* Typography */
/* Spacing */
.image {
  width: 100%; }

.imageLink {
  display: block;
  text-decoration: none;
  cursor: pointer; }

.noClickable {
  pointer-events: none;
  cursor: default; }
