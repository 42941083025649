/* Colors */
/* Typography */
/* Spacing */
.form {
  margin: 0 auto;
  padding: 12px 12px 26px 12px; }

.fields {
  margin: -10px; }

.wrapper {
  max-width: 600px;
  margin: 0 auto;
  position: relative; }

.button {
  margin-top: 20px; }

.points {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #fafafa; }
