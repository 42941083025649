/* Colors */
/* Typography */
/* Spacing */
.swiperWrapper {
  max-width: 591px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px 15px;
  margin-top: 7px; }

.groupTitle {
  color: #0027f4;
  font-size: 19px; }

.prevSlide {
  width: 12px;
  height: 16px;
  background-image: url("./../../../img/icon-arrow-left.svg"), none;
  -webkit-background-size: contain;
          background-size: contain;
  display: block;
  position: absolute;
  left: 0; }

.nextSlide {
  width: 12px;
  height: 16px;
  background-image: url("./../../../img/arrow-next.svg"), none;
  -webkit-background-size: contain;
          background-size: contain;
  display: block;
  position: absolute;
  right: 0; }
