/* Colors */
/* Typography */
/* Spacing */
.groupTitle {
  color: #0027f4;
  font-size: 19px;
  padding: 12px 0; }

.loyallModule {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .loyallModule .phoneRegistrationBox {
    text-align: center; }
  .loyallModule .login {
    color: #666666;
    font-size: 13px; }
  .loyallModule .description {
    color: #666666;
    font-size: 15px; }
  .loyallModule button {
    margin-top: 10px; }
  .loyallModule .obtainPoints {
    font-weight: 500;
    font-size: 121%; }
  .loyallModule .obtainPointsValue {
    font-size: 121%;
    font-weight: 500; }

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100px; }
  .wrapper .verificationCodeMessage {
    margin: 7px 0; }
  .wrapper .verifyCodeSection {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .wrapper .verifyCodeSection .verifyCustomerButton {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      width: 47%; }
  .wrapper .bonusToUse {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .wrapper .bonusToUse input {
      text-align: center; }
    .wrapper .bonusToUse .bonusPointsError {
      color: #f45b4f;
      margin-bottom: 10px; }
  .wrapper .confirmationEmail {
    margin: 10px 0; }
  .wrapper .joinProgramContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
    .wrapper .joinProgramContainer .phonePrefix {
      padding-top: 25px;
      color: #999999; }
    .wrapper .joinProgramContainer button {
      height: 71%;
      margin: 10px 0; }
    .wrapper .joinProgramContainer .phoneContainer {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
    @media only screen and (min-width: 801px) {
      .wrapper .joinProgramContainer {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
            -ms-flex-direction: row;
                flex-direction: row; } }
  .wrapper .spinner {
    position: absolute;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    min-width: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .wrapper .bonusPointsTextContainer {
    margin: 5px 0; }

.loyallHeader {
  color: #000000ff;
  font-size: 29px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 25px;
  margin-top: 15px; }
