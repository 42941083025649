/* Colors */
/* Typography */
/* Spacing */
.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.67); }
  .wrapper .progressCircle {
    position: relative;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: #eaeaea;
    border-radius: 50%;
    margin-right: 10px; }
    .wrapper .progressCircle::after {
      border: none;
      position: absolute;
      top: 2px;
      left: 2px;
      text-align: center;
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color: #fafafa;
      content: " "; }
    .wrapper .progressCircle .leftHalfClipper {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      clip: rect(0, 20px, 20px, 10px); }
      .wrapper .progressCircle .leftHalfClipper .valueBar {
        position: absolute;
        clip: rect(0, 10px, 20px, 0);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #0027f4;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
      .wrapper .progressCircle .leftHalfClipper .first50Bar {
        display: none; }
    .wrapper .progressCircle.over50 .leftHalfClipper {
      clip: rect(auto, auto, auto, auto); }
    .wrapper .progressCircle.over50 .first50Bar {
      display: block;
      position: absolute;
      clip: rect(0, 20px, 20px, 10px);
      background-color: #0027f4;
      border-radius: 50%;
      width: 20px;
      height: 20px; }
    .wrapper .progressCircle.p1 .valueBar {
      -webkit-transform: rotate(3.6deg);
           -o-transform: rotate(3.6deg);
              transform: rotate(3.6deg); }
    .wrapper .progressCircle.p2 .valueBar {
      -webkit-transform: rotate(7.2deg);
           -o-transform: rotate(7.2deg);
              transform: rotate(7.2deg); }
    .wrapper .progressCircle.p3 .valueBar {
      -webkit-transform: rotate(10.8deg);
           -o-transform: rotate(10.8deg);
              transform: rotate(10.8deg); }
    .wrapper .progressCircle.p4 .valueBar {
      -webkit-transform: rotate(14.4deg);
           -o-transform: rotate(14.4deg);
              transform: rotate(14.4deg); }
    .wrapper .progressCircle.p5 .valueBar {
      -webkit-transform: rotate(18deg);
           -o-transform: rotate(18deg);
              transform: rotate(18deg); }
    .wrapper .progressCircle.p6 .valueBar {
      -webkit-transform: rotate(21.6deg);
           -o-transform: rotate(21.6deg);
              transform: rotate(21.6deg); }
    .wrapper .progressCircle.p7 .valueBar {
      -webkit-transform: rotate(25.2deg);
           -o-transform: rotate(25.2deg);
              transform: rotate(25.2deg); }
    .wrapper .progressCircle.p8 .valueBar {
      -webkit-transform: rotate(28.8deg);
           -o-transform: rotate(28.8deg);
              transform: rotate(28.8deg); }
    .wrapper .progressCircle.p9 .valueBar {
      -webkit-transform: rotate(32.4deg);
           -o-transform: rotate(32.4deg);
              transform: rotate(32.4deg); }
    .wrapper .progressCircle.p10 .valueBar {
      -webkit-transform: rotate(36deg);
           -o-transform: rotate(36deg);
              transform: rotate(36deg); }
    .wrapper .progressCircle.p11 .valueBar {
      -webkit-transform: rotate(39.6deg);
           -o-transform: rotate(39.6deg);
              transform: rotate(39.6deg); }
    .wrapper .progressCircle.p12 .valueBar {
      -webkit-transform: rotate(43.2deg);
           -o-transform: rotate(43.2deg);
              transform: rotate(43.2deg); }
    .wrapper .progressCircle.p13 .valueBar {
      -webkit-transform: rotate(46.8deg);
           -o-transform: rotate(46.8deg);
              transform: rotate(46.8deg); }
    .wrapper .progressCircle.p14 .valueBar {
      -webkit-transform: rotate(50.4deg);
           -o-transform: rotate(50.4deg);
              transform: rotate(50.4deg); }
    .wrapper .progressCircle.p15 .valueBar {
      -webkit-transform: rotate(54deg);
           -o-transform: rotate(54deg);
              transform: rotate(54deg); }
    .wrapper .progressCircle.p16 .valueBar {
      -webkit-transform: rotate(57.6deg);
           -o-transform: rotate(57.6deg);
              transform: rotate(57.6deg); }
    .wrapper .progressCircle.p17 .valueBar {
      -webkit-transform: rotate(61.2deg);
           -o-transform: rotate(61.2deg);
              transform: rotate(61.2deg); }
    .wrapper .progressCircle.p18 .valueBar {
      -webkit-transform: rotate(64.8deg);
           -o-transform: rotate(64.8deg);
              transform: rotate(64.8deg); }
    .wrapper .progressCircle.p19 .valueBar {
      -webkit-transform: rotate(68.4deg);
           -o-transform: rotate(68.4deg);
              transform: rotate(68.4deg); }
    .wrapper .progressCircle.p20 .valueBar {
      -webkit-transform: rotate(72deg);
           -o-transform: rotate(72deg);
              transform: rotate(72deg); }
    .wrapper .progressCircle.p21 .valueBar {
      -webkit-transform: rotate(75.6deg);
           -o-transform: rotate(75.6deg);
              transform: rotate(75.6deg); }
    .wrapper .progressCircle.p22 .valueBar {
      -webkit-transform: rotate(79.2deg);
           -o-transform: rotate(79.2deg);
              transform: rotate(79.2deg); }
    .wrapper .progressCircle.p23 .valueBar {
      -webkit-transform: rotate(82.8deg);
           -o-transform: rotate(82.8deg);
              transform: rotate(82.8deg); }
    .wrapper .progressCircle.p24 .valueBar {
      -webkit-transform: rotate(86.4deg);
           -o-transform: rotate(86.4deg);
              transform: rotate(86.4deg); }
    .wrapper .progressCircle.p25 .valueBar {
      -webkit-transform: rotate(90deg);
           -o-transform: rotate(90deg);
              transform: rotate(90deg); }
    .wrapper .progressCircle.p26 .valueBar {
      -webkit-transform: rotate(93.6deg);
           -o-transform: rotate(93.6deg);
              transform: rotate(93.6deg); }
    .wrapper .progressCircle.p27 .valueBar {
      -webkit-transform: rotate(97.2deg);
           -o-transform: rotate(97.2deg);
              transform: rotate(97.2deg); }
    .wrapper .progressCircle.p28 .valueBar {
      -webkit-transform: rotate(100.8deg);
           -o-transform: rotate(100.8deg);
              transform: rotate(100.8deg); }
    .wrapper .progressCircle.p29 .valueBar {
      -webkit-transform: rotate(104.4deg);
           -o-transform: rotate(104.4deg);
              transform: rotate(104.4deg); }
    .wrapper .progressCircle.p30 .valueBar {
      -webkit-transform: rotate(108deg);
           -o-transform: rotate(108deg);
              transform: rotate(108deg); }
    .wrapper .progressCircle.p31 .valueBar {
      -webkit-transform: rotate(111.6deg);
           -o-transform: rotate(111.6deg);
              transform: rotate(111.6deg); }
    .wrapper .progressCircle.p32 .valueBar {
      -webkit-transform: rotate(115.2deg);
           -o-transform: rotate(115.2deg);
              transform: rotate(115.2deg); }
    .wrapper .progressCircle.p33 .valueBar {
      -webkit-transform: rotate(118.8deg);
           -o-transform: rotate(118.8deg);
              transform: rotate(118.8deg); }
    .wrapper .progressCircle.p34 .valueBar {
      -webkit-transform: rotate(122.4deg);
           -o-transform: rotate(122.4deg);
              transform: rotate(122.4deg); }
    .wrapper .progressCircle.p35 .valueBar {
      -webkit-transform: rotate(126deg);
           -o-transform: rotate(126deg);
              transform: rotate(126deg); }
    .wrapper .progressCircle.p36 .valueBar {
      -webkit-transform: rotate(129.6deg);
           -o-transform: rotate(129.6deg);
              transform: rotate(129.6deg); }
    .wrapper .progressCircle.p37 .valueBar {
      -webkit-transform: rotate(133.2deg);
           -o-transform: rotate(133.2deg);
              transform: rotate(133.2deg); }
    .wrapper .progressCircle.p38 .valueBar {
      -webkit-transform: rotate(136.8deg);
           -o-transform: rotate(136.8deg);
              transform: rotate(136.8deg); }
    .wrapper .progressCircle.p39 .valueBar {
      -webkit-transform: rotate(140.4deg);
           -o-transform: rotate(140.4deg);
              transform: rotate(140.4deg); }
    .wrapper .progressCircle.p40 .valueBar {
      -webkit-transform: rotate(144deg);
           -o-transform: rotate(144deg);
              transform: rotate(144deg); }
    .wrapper .progressCircle.p41 .valueBar {
      -webkit-transform: rotate(147.6deg);
           -o-transform: rotate(147.6deg);
              transform: rotate(147.6deg); }
    .wrapper .progressCircle.p42 .valueBar {
      -webkit-transform: rotate(151.2deg);
           -o-transform: rotate(151.2deg);
              transform: rotate(151.2deg); }
    .wrapper .progressCircle.p43 .valueBar {
      -webkit-transform: rotate(154.8deg);
           -o-transform: rotate(154.8deg);
              transform: rotate(154.8deg); }
    .wrapper .progressCircle.p44 .valueBar {
      -webkit-transform: rotate(158.4deg);
           -o-transform: rotate(158.4deg);
              transform: rotate(158.4deg); }
    .wrapper .progressCircle.p45 .valueBar {
      -webkit-transform: rotate(162deg);
           -o-transform: rotate(162deg);
              transform: rotate(162deg); }
    .wrapper .progressCircle.p46 .valueBar {
      -webkit-transform: rotate(165.6deg);
           -o-transform: rotate(165.6deg);
              transform: rotate(165.6deg); }
    .wrapper .progressCircle.p47 .valueBar {
      -webkit-transform: rotate(169.2deg);
           -o-transform: rotate(169.2deg);
              transform: rotate(169.2deg); }
    .wrapper .progressCircle.p48 .valueBar {
      -webkit-transform: rotate(172.8deg);
           -o-transform: rotate(172.8deg);
              transform: rotate(172.8deg); }
    .wrapper .progressCircle.p49 .valueBar {
      -webkit-transform: rotate(176.4deg);
           -o-transform: rotate(176.4deg);
              transform: rotate(176.4deg); }
    .wrapper .progressCircle.p50 .valueBar {
      -webkit-transform: rotate(180deg);
           -o-transform: rotate(180deg);
              transform: rotate(180deg); }
    .wrapper .progressCircle.p51 .valueBar {
      -webkit-transform: rotate(183.6deg);
           -o-transform: rotate(183.6deg);
              transform: rotate(183.6deg); }
    .wrapper .progressCircle.p52 .valueBar {
      -webkit-transform: rotate(187.2deg);
           -o-transform: rotate(187.2deg);
              transform: rotate(187.2deg); }
    .wrapper .progressCircle.p53 .valueBar {
      -webkit-transform: rotate(190.8deg);
           -o-transform: rotate(190.8deg);
              transform: rotate(190.8deg); }
    .wrapper .progressCircle.p54 .valueBar {
      -webkit-transform: rotate(194.4deg);
           -o-transform: rotate(194.4deg);
              transform: rotate(194.4deg); }
    .wrapper .progressCircle.p55 .valueBar {
      -webkit-transform: rotate(198deg);
           -o-transform: rotate(198deg);
              transform: rotate(198deg); }
    .wrapper .progressCircle.p56 .valueBar {
      -webkit-transform: rotate(201.6deg);
           -o-transform: rotate(201.6deg);
              transform: rotate(201.6deg); }
    .wrapper .progressCircle.p57 .valueBar {
      -webkit-transform: rotate(205.2deg);
           -o-transform: rotate(205.2deg);
              transform: rotate(205.2deg); }
    .wrapper .progressCircle.p58 .valueBar {
      -webkit-transform: rotate(208.8deg);
           -o-transform: rotate(208.8deg);
              transform: rotate(208.8deg); }
    .wrapper .progressCircle.p59 .valueBar {
      -webkit-transform: rotate(212.4deg);
           -o-transform: rotate(212.4deg);
              transform: rotate(212.4deg); }
    .wrapper .progressCircle.p60 .valueBar {
      -webkit-transform: rotate(216deg);
           -o-transform: rotate(216deg);
              transform: rotate(216deg); }
    .wrapper .progressCircle.p61 .valueBar {
      -webkit-transform: rotate(219.6deg);
           -o-transform: rotate(219.6deg);
              transform: rotate(219.6deg); }
    .wrapper .progressCircle.p62 .valueBar {
      -webkit-transform: rotate(223.2deg);
           -o-transform: rotate(223.2deg);
              transform: rotate(223.2deg); }
    .wrapper .progressCircle.p63 .valueBar {
      -webkit-transform: rotate(226.8deg);
           -o-transform: rotate(226.8deg);
              transform: rotate(226.8deg); }
    .wrapper .progressCircle.p64 .valueBar {
      -webkit-transform: rotate(230.4deg);
           -o-transform: rotate(230.4deg);
              transform: rotate(230.4deg); }
    .wrapper .progressCircle.p65 .valueBar {
      -webkit-transform: rotate(234deg);
           -o-transform: rotate(234deg);
              transform: rotate(234deg); }
    .wrapper .progressCircle.p66 .valueBar {
      -webkit-transform: rotate(237.6deg);
           -o-transform: rotate(237.6deg);
              transform: rotate(237.6deg); }
    .wrapper .progressCircle.p67 .valueBar {
      -webkit-transform: rotate(241.2deg);
           -o-transform: rotate(241.2deg);
              transform: rotate(241.2deg); }
    .wrapper .progressCircle.p68 .valueBar {
      -webkit-transform: rotate(244.8deg);
           -o-transform: rotate(244.8deg);
              transform: rotate(244.8deg); }
    .wrapper .progressCircle.p69 .valueBar {
      -webkit-transform: rotate(248.4deg);
           -o-transform: rotate(248.4deg);
              transform: rotate(248.4deg); }
    .wrapper .progressCircle.p70 .valueBar {
      -webkit-transform: rotate(252deg);
           -o-transform: rotate(252deg);
              transform: rotate(252deg); }
    .wrapper .progressCircle.p71 .valueBar {
      -webkit-transform: rotate(255.6deg);
           -o-transform: rotate(255.6deg);
              transform: rotate(255.6deg); }
    .wrapper .progressCircle.p72 .valueBar {
      -webkit-transform: rotate(259.2deg);
           -o-transform: rotate(259.2deg);
              transform: rotate(259.2deg); }
    .wrapper .progressCircle.p73 .valueBar {
      -webkit-transform: rotate(262.8deg);
           -o-transform: rotate(262.8deg);
              transform: rotate(262.8deg); }
    .wrapper .progressCircle.p74 .valueBar {
      -webkit-transform: rotate(266.4deg);
           -o-transform: rotate(266.4deg);
              transform: rotate(266.4deg); }
    .wrapper .progressCircle.p75 .valueBar {
      -webkit-transform: rotate(270deg);
           -o-transform: rotate(270deg);
              transform: rotate(270deg); }
    .wrapper .progressCircle.p76 .valueBar {
      -webkit-transform: rotate(273.6deg);
           -o-transform: rotate(273.6deg);
              transform: rotate(273.6deg); }
    .wrapper .progressCircle.p77 .valueBar {
      -webkit-transform: rotate(277.2deg);
           -o-transform: rotate(277.2deg);
              transform: rotate(277.2deg); }
    .wrapper .progressCircle.p78 .valueBar {
      -webkit-transform: rotate(280.8deg);
           -o-transform: rotate(280.8deg);
              transform: rotate(280.8deg); }
    .wrapper .progressCircle.p79 .valueBar {
      -webkit-transform: rotate(284.4deg);
           -o-transform: rotate(284.4deg);
              transform: rotate(284.4deg); }
    .wrapper .progressCircle.p80 .valueBar {
      -webkit-transform: rotate(288deg);
           -o-transform: rotate(288deg);
              transform: rotate(288deg); }
    .wrapper .progressCircle.p81 .valueBar {
      -webkit-transform: rotate(291.6deg);
           -o-transform: rotate(291.6deg);
              transform: rotate(291.6deg); }
    .wrapper .progressCircle.p82 .valueBar {
      -webkit-transform: rotate(295.2deg);
           -o-transform: rotate(295.2deg);
              transform: rotate(295.2deg); }
    .wrapper .progressCircle.p83 .valueBar {
      -webkit-transform: rotate(298.8deg);
           -o-transform: rotate(298.8deg);
              transform: rotate(298.8deg); }
    .wrapper .progressCircle.p84 .valueBar {
      -webkit-transform: rotate(302.4deg);
           -o-transform: rotate(302.4deg);
              transform: rotate(302.4deg); }
    .wrapper .progressCircle.p85 .valueBar {
      -webkit-transform: rotate(306deg);
           -o-transform: rotate(306deg);
              transform: rotate(306deg); }
    .wrapper .progressCircle.p86 .valueBar {
      -webkit-transform: rotate(309.6deg);
           -o-transform: rotate(309.6deg);
              transform: rotate(309.6deg); }
    .wrapper .progressCircle.p87 .valueBar {
      -webkit-transform: rotate(313.2deg);
           -o-transform: rotate(313.2deg);
              transform: rotate(313.2deg); }
    .wrapper .progressCircle.p88 .valueBar {
      -webkit-transform: rotate(316.8deg);
           -o-transform: rotate(316.8deg);
              transform: rotate(316.8deg); }
    .wrapper .progressCircle.p89 .valueBar {
      -webkit-transform: rotate(320.4deg);
           -o-transform: rotate(320.4deg);
              transform: rotate(320.4deg); }
    .wrapper .progressCircle.p90 .valueBar {
      -webkit-transform: rotate(324deg);
           -o-transform: rotate(324deg);
              transform: rotate(324deg); }
    .wrapper .progressCircle.p91 .valueBar {
      -webkit-transform: rotate(327.6deg);
           -o-transform: rotate(327.6deg);
              transform: rotate(327.6deg); }
    .wrapper .progressCircle.p92 .valueBar {
      -webkit-transform: rotate(331.2deg);
           -o-transform: rotate(331.2deg);
              transform: rotate(331.2deg); }
    .wrapper .progressCircle.p93 .valueBar {
      -webkit-transform: rotate(334.8deg);
           -o-transform: rotate(334.8deg);
              transform: rotate(334.8deg); }
    .wrapper .progressCircle.p94 .valueBar {
      -webkit-transform: rotate(338.4deg);
           -o-transform: rotate(338.4deg);
              transform: rotate(338.4deg); }
    .wrapper .progressCircle.p95 .valueBar {
      -webkit-transform: rotate(342deg);
           -o-transform: rotate(342deg);
              transform: rotate(342deg); }
    .wrapper .progressCircle.p96 .valueBar {
      -webkit-transform: rotate(345.6deg);
           -o-transform: rotate(345.6deg);
              transform: rotate(345.6deg); }
    .wrapper .progressCircle.p97 .valueBar {
      -webkit-transform: rotate(349.2deg);
           -o-transform: rotate(349.2deg);
              transform: rotate(349.2deg); }
    .wrapper .progressCircle.p98 .valueBar {
      -webkit-transform: rotate(352.8deg);
           -o-transform: rotate(352.8deg);
              transform: rotate(352.8deg); }
    .wrapper .progressCircle.p99 .valueBar {
      -webkit-transform: rotate(356.4deg);
           -o-transform: rotate(356.4deg);
              transform: rotate(356.4deg); }
    .wrapper .progressCircle.p100 .valueBar {
      -webkit-transform: rotate(360deg);
           -o-transform: rotate(360deg);
              transform: rotate(360deg); }
  .wrapper .description {
    line-height: 20px;
    vertical-align: middle; }
