/* Colors */
/* Typography */
/* Spacing */
.number {
  margin-right: 12px;
  font-size: 13px;
  font-weight: 300;
  background: #b6b6b6;
  border: 1px solid #b6b6b6;
  border-radius: 50%;
  color: #fff;
  width: 22px;
  height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.accent {
  background: #0027f4;
  border: 1px solid #0027f4; }

.withIcon {
  background: transparent;
  border: 1px solid #0027f4; }
