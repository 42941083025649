/* Colors */
/* Typography */
/* Spacing */
.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  position: absolute;
  z-index: 7;
  width: 100%;
  left: 0; }
  .wrapper .label {
    cursor: pointer;
    margin: 0 10px;
    padding: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    background-color: #fbfbfb;
    border: 1px solid #dfdfdf;
    border-radius: 4px; }
    .wrapper .label:hover {
      background-color: #fff; }
    .wrapper .label::before {
      content: '';
      display: block;
      width: 19px;
      height: 17px;
      margin-right: 6px;
      background-image: url("./../../../img/current-location.svg");
      background-repeat: no-repeat; }
