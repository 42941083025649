/* Colors */
/* Typography */
/* Spacing */
.separator::after {
  content: '|';
  display: inline-block;
  margin: 0 10px; }

.logout {
  cursor: pointer; }
