/* Colors */
/* Typography */
/* Spacing */
.component {
  padding: 10px;
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.inner {
  position: relative;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  float: left; }
  .inner::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background-position: center center;
    background-repeat: no-repeat;
    pointer-events: none;
    background-image: url("./../../../img/icon-arrow-down.svg"), none;
    -webkit-background-size: 19px 19px;
            background-size: 19px;
    z-index: 7; }

.label {
  font-size: 11px;
  line-height: 1.1;
  font-weight: normal;
  color: #999999;
  z-index: 7;
  top: 0;
  position: absolute;
  width: 100%;
  margin-top: 12px;
  padding: 0 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out, -o-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out, -webkit-transform 0.2s ease-out, -o-transform 0.2s ease-out; }

.select {
  background-color: white;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  padding: 14px 12px;
  font-size: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #e0e0e0;
  width: 100%;
  border-radius: 4px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-font-smoothing: inherit; }
  @media only screen and (min-width: 801px) {
    .select {
      font-size: 14px; } }
  .select::-ms-expand {
    display: none; }

.error {
  border-color: #f45b4f;
  -webkit-box-shadow: 0 0 0 1px #f45b4f;
          box-shadow: 0 0 0 1px #f45b4f;
  background: #fffefe; }
  .error:focus {
    border-color: #f45b4f;
    -webkit-box-shadow: 0 0 0 1px #f45b4f;
            box-shadow: 0 0 0 1px #f45b4f; }

.errorWrapper {
  display: block;
  float: left;
  width: 100%; }

.withLabel .select {
  padding-top: 23px;
  padding-bottom: 8px; }

.medium {
  width: 100%; }
  @media only screen and (min-width: 801px) {
    .medium {
      width: 65%; } }

.small {
  width: 100%; }
  @media only screen and (min-width: 801px) {
    .small {
      width: 35%; } }

.half {
  width: 100%; }
  @media only screen and (min-width: 801px) {
    .half {
      width: 50%; } }

.readOnly {
  margin-right: 2px;
  width: auto;
  pointer-events: none;
  display: inline-block;
  padding: 0;
  float: none; }
  .readOnly::before {
    display: none; }
  .readOnly::after {
    content: ',';
    display: inline-block; }
  .readOnly:last-child::after {
    display: none; }
  .readOnly .select {
    border: none;
    background: transparent;
    font-size: 14px;
    color: inherit; }

.disabled {
  pointer-events: none; }
  .disabled .select {
    background: #fbfbfb;
    color: #6d6d6d; }
  .disabled .inner::after {
    opacity: 0.4; }
