/* Colors */
/* Typography */
/* Spacing */
.wrapper {
  max-width: 360px;
  padding-bottom: 40px;
  margin: 0 auto; }
  @media only screen and (min-width: 801px) {
    .wrapper {
      min-width: 360px; } }

.form {
  position: relative;
  margin: -10px; }

.loading {
  opacity: 0.7;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  pointer-events: none; }
