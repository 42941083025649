/* Colors */
/* Typography */
/* Spacing */
.header {
  position: relative;
  z-index: 22; }
  .header .content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 16px 10px;
    background-color: #fafafa; }

.sticky {
  z-index: 50;
  -webkit-box-shadow: 0 1px 3px #e8e8e8;
          box-shadow: 0 1px 3px #e8e8e8; }
