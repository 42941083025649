/* Colors */
/* Typography */
/* Spacing */
.messages {
  text-align: center;
  max-width: 70%;
  margin: 0 auto; }

.message {
  padding-bottom: 6px; }

.messagesList {
  margin-top: 6px; }

.title {
  font-size: 22px; }

.code {
  font-size: 12px;
  margin: 6px 0 20px 0;
  color: #666666; }

.name {
  padding: 12px 0; }

.icon {
  height: 72px;
  width: 72px;
  margin: 0 auto 12px; }

.buttonWrapper {
  padding: 64px 0; }

.button {
  font-size: 16px;
  color: #0027f4;
  text-decoration: none;
  background: transparent;
  display: inline-block;
  cursor: pointer;
  border: 0; }
