.wrapper {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px; }

.title {
  text-transform: uppercase; }

.text {
  width: 100%;
  text-align: justify; }
