/* Colors */
/* Typography */
/* Spacing */
.inputWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px; }
  .inputWrapper .input {
    width: 100%; }
  .inputWrapper input {
    border-radius: 4px 0 0 4px; }
  .inputWrapper button {
    background: #0027f4;
    color: white;
    min-width: 120px;
    max-width: 120px;
    max-height: 50px;
    cursor: pointer;
    padding: 12px 24px;
    border-radius: 0 4px 4px 0; }
    .inputWrapper button[disabled] {
      pointer-events: none;
      background-color: #f2f0e6;
      color: #999999;
      -webkit-box-shadow: none;
              box-shadow: none; }
