/* Colors */
/* Typography */
/* Spacing */
.itemsContainer {
  margin-left: 35px; }

.showBundle {
  text-decoration: underline;
  border: none;
  background-color: transparent;
  padding: 10px 0 20px; }
  .showBundle:focus {
    outline: none; }

.bundleSection {
  padding-bottom: 20px; }
