/* Colors */
/* Typography */
/* Spacing */
.cart {
  position: fixed;
  bottom: 0;
  will-change: transform, width;
  -webkit-transform: translateX(103%);
       -o-transform: translateX(103%);
          transform: translateX(103%);
  -webkit-transition: width 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: width 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: width 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -o-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: width 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: width 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -o-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  top: 0;
  right: 0;
  z-index: 6200;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fafafa;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); }
  @media only screen and (min-width: 801px) {
    .cart {
      width: 450px; } }
  .cart .note,
  .cart .note * {
    color: rgba(0, 0, 0, 0.67);
    font-size: 12px;
    line-height: 1.4;
    font-style: italic;
    text-align: center; }

.overlay {
  display: none; }
  @media only screen and (min-width: 801px) {
    .overlay {
      display: block;
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 2;
      background: rgba(0, 0, 0, 0.1); } }

.inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  position: relative;
  -webkit-transform: translateX(8px);
       -o-transform: translateX(8px);
          transform: translateX(8px);
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0.28s;
  transition: -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0.28s;
  -o-transition: -o-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0.28s;
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0.28s;
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0.28s, -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0.28s, -o-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0.28s; }

.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 30px;
  padding: 12px; }
  @media only screen and (min-width: 801px) {
    .header {
      padding: 12px 24px; } }

.main {
  background: transparent;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.footer {
  background: transparent; }

.open {
  -webkit-transform: translateX(0);
       -o-transform: translateX(0);
          transform: translateX(0); }
  .open .inner {
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0); }

.overflowed .header {
  -webkit-box-shadow: 2px 0 3px rgba(0, 0, 0, 0.2);
          box-shadow: 2px 0 3px rgba(0, 0, 0, 0.2);
  background: #fbfbfb;
  z-index: 1; }

.overflowed .main {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto; }

.overflowed .footer {
  -webkit-box-shadow: 2px 0 3px rgba(0, 0, 0, 0.2);
          box-shadow: 2px 0 3px rgba(0, 0, 0, 0.2);
  background: #fbfbfb;
  z-index: 1; }

.separator {
  text-align: center;
  margin: 16px 0;
  font-size: 14px; }
