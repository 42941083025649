/* Colors */
/* Typography */
/* Spacing */
.column {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0;
      -ms-flex: 1 0;
          flex: 1 0; }

.small {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
  position: relative; }
  @media only screen and (min-width: 801px) {
    .small {
      -webkit-flex-basis: 40%;
          -ms-flex-preferred-size: 40%;
              flex-basis: 40%; }
      .small::after {
        content: '';
        display: block;
        width: 300%;
        position: absolute;
        top: 0;
        bottom: 0;
        background: #fafafa;
        z-index: -1;
        left: 0;
        background-position: left top;
        pointer-events: none;
        height: 100vh;
        min-height: 100%; } }
