/* Colors */
/* Typography */
.small .label {
  font-size: 14px;
  line-height: 1.4; }

.smaller .label {
  font-size: 11px;
  color: #666666;
  line-height: 1.4; }

/* Spacing */
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 6px;
  font-size: 14px; }

.label {
  display: inline;
  color: rgba(0, 0, 0, 0.67); }

.value {
  display: inline;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87); }

.total {
  font-size: 19px;
  padding-top: 20px;
  margin-top: 14px;
  font-weight: 600;
  border-top: 1px solid #e0e0e0;
  line-height: 24px; }
  @media only screen and (min-width: 801px) {
    .total {
      margin-top: 20px; } }
  .total .label {
    color: rgba(0, 0, 0, 0.87); }

.small .value {
  font-size: 14px; }

.smaller .label {
  text-align: right; }

.smaller .value {
  font-size: 11px; }
