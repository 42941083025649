/* Colors */
/* Typography */
/* Spacing */
.button {
  width: 26px;
  height: 26px;
  background: none;
  color: currentColor;
  border: 0;
  padding: 0;
  -webkit-box-sizing: content-box;
          box-sizing: content-box; }
  .button:hover {
    cursor: pointer; }
  .button:hover, .button:active, .button:focus {
    outline: none; }

.big {
  width: 26px;
  height: 26px;
  padding: 10px; }
