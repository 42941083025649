/* Colors */
/* Typography */
/* Spacing */
.form::before, .form::after {
  content: '';
  display: table; }

.form::after {
  clear: both; }

.checkbox {
  padding-bottom: 20px; }

.fields {
  padding-bottom: 12px;
  margin: -10px; }

.addressReadOnly {
  display: block;
  margin: 0;
  padding-bottom: 0;
  width: 80%; }
  .addressReadOnly .fields {
    padding-bottom: 0;
    margin: 0; }
  @media only screen and (min-width: 801px) {
    .addressReadOnly {
      width: 60%; } }
