/* Colors */
/* Typography */
/* Spacing */
.readOnly {
  margin-right: 4px;
  width: auto;
  pointer-events: none;
  display: inline-block; }
  .readOnly span {
    border: none;
    padding: 0; }
  .readOnly::after {
    content: ',';
    display: inline-block; }
  .readOnly:last-child::after {
    display: none; }

.component {
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.label {
  font-size: 11px;
  line-height: 1.1;
  font-weight: normal;
  color: #999999;
  opacity: 0;
  z-index: 1;
  top: 0;
  position: absolute;
  width: 100%;
  margin-top: 22px;
  padding: 0 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transform: translateY(3px);
       -o-transform: translateY(3px);
          transform: translateY(3px);
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out, -o-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out, -webkit-transform 0.2s ease-out, -o-transform 0.2s ease-out; }

.input {
  padding: 14px 12px;
  border: 1px solid #e0e0e0;
  width: 100%;
  background-color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: inherit;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  -webkit-transition: opacity 0.2s ease-out, -webkit-box-shadow 0.2s ease-out;
  transition: opacity 0.2s ease-out, -webkit-box-shadow 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out, box-shadow 0.2s ease-out;
  transition: opacity 0.2s ease-out, box-shadow 0.2s ease-out;
  transition: opacity 0.2s ease-out, box-shadow 0.2s ease-out, -webkit-box-shadow 0.2s ease-out;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
            box-shadow: 0 0 0 1000px transparent inset; }
  @media only screen and (min-width: 801px) {
    .input {
      font-size: 14px; } }
  .input::-webkit-input-placeholder {
    color: #999999; }
  .input::-moz-placeholder {
    color: #999999; }
  .input::placeholder {
    color: #999999; }
  .input:focus {
    outline: none;
    border-color: #b6b6b6;
    -webkit-box-shadow: 0 0 1px 1px #e0e0e0;
            box-shadow: 0 0 1px 1px #e0e0e0; }

.withSelect .label {
  padding-left: 57px; }

.withSelect .input {
  padding-left: 57px; }

.simple {
  padding: 0; }
  .simple .label {
    margin-top: 11px; }

.medium {
  width: 100%; }
  @media only screen and (min-width: 801px) {
    .medium {
      width: 65%; } }

.small {
  width: 100%; }
  @media only screen and (min-width: 801px) {
    .small {
      width: 35%; } }

.half {
  width: 100%; }
  @media only screen and (min-width: 801px) {
    .half {
      width: 50%; } }

.floatingLabel .label {
  -webkit-transform: none;
       -o-transform: none;
          transform: none;
  opacity: 1; }

.floatingLabel .input {
  padding-top: 22px;
  padding-bottom: 6px; }
  .floatingLabel .input::-webkit-input-placeholder {
    color: transparent; }
  .floatingLabel .input::-moz-placeholder {
    color: transparent; }
  .floatingLabel .input::placeholder {
    color: transparent; }

.error {
  border-color: #f45b4f;
  -webkit-box-shadow: 0 0 0 1px #f45b4f;
          box-shadow: 0 0 0 1px #f45b4f;
  background: rgba(255, 16, 0, 0.0196078); }
  .error:focus {
    border-color: #f45b4f;
    -webkit-box-shadow: 0 0 0 1px #f45b4f;
            box-shadow: 0 0 0 1px #f45b4f; }

.errorWrapper {
  display: block; }

.appendIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translateY(50%);
       -o-transform: translateY(50%);
          transform: translateY(50%); }
  .appendIcon > img {
    height: 37px; }

.disabled {
  pointer-events: none;
  background: #fbfbfb;
  color: #6d6d6d; }
