.wrapper {
  position: absolute;
  z-index: 7;
  width: 100%;
  margin: 0 -10px; }

.content {
  border-radius: 4px;
  margin: 0 10px; }
  .content.active {
    border: 1px solid #b6b6b6;
    background: #fff;
    -webkit-box-shadow: 0 0 1px 1px #b6b6b6;
            box-shadow: 0 0 1px 1px #b6b6b6; }

.suggestionItem {
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
  border-bottom: 1px solid #e2e2e2;
  color: #999999; }
  .suggestionItem :last-child {
    border-bottom: none; }
  .suggestionItem.active {
    background-color: #fafafa;
    color: #000; }
  .suggestionItem .text {
    display: block; }
