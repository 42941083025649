/* Colors */
/* Typography */
/* Spacing */
.content {
  text-align: center;
  margin-top: 118px; }
  .content::before {
    content: '';
    display: block;
    width: 72px;
    height: 72px;
    margin: 0 auto 10px;
    background-image: url("./../../../img/empty-bag.svg");
    opacity: 0.2;
    -webkit-background-size: 100% 100%;
            background-size: 100% 100%; }

.message {
  position: relative; }

.bottom {
  width: 300px;
  margin: 0 auto;
  padding-top: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }

.link {
  text-decoration: none;
  color: #0027f4; }
