/* Colors */
/* Typography */
.price {
  font-size: 14px;
  line-height: 1.4; }

.total, .quantity {
  font-size: 11px;
  color: #666666;
  line-height: 1.4; }

/* Spacing */
.price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }

.value {
  color: #000; }

.beforeDiscount {
  color: #666666;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  place-self: flex-end; }
  .beforeDiscount::before {
    border-top: 1px solid;
    border-color: #666666;
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
       transform: rotate(0);
    position: absolute;
    top: 50%;
    content: "";
    width: 100%; }

.discounted {
  color: #f3725b; }

.total {
  text-align: right;
  color: #666666; }

.quantity {
  text-align: right;
  margin-right: 2px;
  color: #666666; }
