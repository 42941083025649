/* Colors */
/* Typography */
/* Spacing */
.wrapper {
  width: 100%; }

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 12px 100px 12px; }
  @media only screen and (min-width: 801px) {
    .container {
      padding: 22px 12px 100px 12px; } }

.price {
  padding: 4px 0;
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-weight: 600; }

.buttonWrapper {
  margin-top: 32px; }
