.minicart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.label {
  position: relative;
  margin-right: 4px; }

.quantity::after {
  content: ')'; }

.quantity::before {
  content: '('; }

.withLabel {
  position: relative; }

.empty {
  position: relative; }
