/* Colors */
/* Typography */
/* Spacing */
.readOnly .shippingName {
  margin-right: 6px; }

.readOnly .shippingPrice::before {
  content: '(';
  display: inline-block; }

.readOnly .shippingPrice::after {
  content: ')';
  display: inline-block; }

.button {
  padding: 12px 0; }
