/* Colors */
/* Typography */
/* Spacing */
.container {
  position: relative; }

.columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: initial;
  position: relative; }
  @media only screen and (min-width: 801px) {
    .columns {
      padding-bottom: 32px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
      overflow: hidden; } }

.wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  margin: 0 auto; }

.content {
  position: relative;
  width: 100%; }
  @media only screen and (min-width: 801px) {
    .content {
      padding-right: 10%;
      width: auto;
      overflow: hidden; } }
