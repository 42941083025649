@-webkit-keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-o-keyframes spinnerAnimation {
  0% {
    -o-transform: rotate(0deg);
       transform: rotate(0deg); }
  100% {
    -o-transform: rotate(360deg);
       transform: rotate(360deg); } }

@keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg); } }

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .wrapperWithOverlay {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 999;
    pointer-events: none; }

.spinner {
  display: block;
  margin: auto;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  -webkit-animation: spinnerAnimation 1.25s linear infinite;
       -o-animation: spinnerAnimation 1.25s linear infinite;
          animation: spinnerAnimation 1.25s linear infinite;
  position: absolute;
  z-index: 999; }
