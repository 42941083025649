.icon {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  -webkit-background-size: contain;
          background-size: contain;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%; }

.plus {
  background-image: url("./../../../img/plus.svg"); }

.minus {
  background-image: url("./../../../img/minus.svg"); }

.checkMark {
  background-image: url("./../../../img/check-mark.svg"); }

.bag {
  background-image: url("./../../../img/bag.svg"); }

.arrowLeft {
  background-image: url("./../../../img/icon-arrow-left.svg"); }

.arrowRight {
  background-image: url("./../../../img/arrow-next.svg"); }

.error {
  background-image: url("./../../../img/error.svg"); }

.success {
  background-image: url("./../../../img/success.svg"); }

.lock {
  background-image: url("./../../../img/lock.svg"); }

.information {
  background-image: url("./../../../img/icon-info.svg"); }
