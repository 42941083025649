/* Colors */
/* Typography */
.name {
  font-size: 14px;
  line-height: 1;
  font-weight: 400; }
  @media only screen and (min-width: 801px) {
    .name {
      line-height: 1.4; } }

/* Spacing */
.link {
  color: #000;
  text-decoration: none;
  display: block;
  cursor: pointer; }

.big {
  font-size: 23px;
  line-height: 1.4; }

.noClickable {
  pointer-events: none;
  cursor: default; }
