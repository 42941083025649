/* Colors */
/* Typography */
/* Spacing */
@-webkit-keyframes fadein {
  from {
    opacity: 0; } }
@-o-keyframes fadein {
  from {
    opacity: 0; } }
@keyframes fadein {
  from {
    opacity: 0; } }

.box {
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  line-height: 1.4;
  -webkit-animation: fadein 0.2s ease-in-out;
       -o-animation: fadein 0.2s ease-in-out;
          animation: fadein 0.2s ease-in-out; }
  .box p {
    margin: 0 0 16px 0; }
    .box p:last-child {
      margin: 0; }

.withBorder {
  border-width: 1px;
  border-style: solid; }

.error {
  color: #d0021b;
  background: rgba(206, 17, 38, 0.05); }
  .error .withBorder {
    border-color: #d0021b; }

.warning {
  color: #d09d02;
  background: rgba(206, 165, 17, 0.05); }
  .warning .withBorder {
    border-color: #d09d02; }

.info {
  background: #f5f5f5; }
  .info .withBorder {
    border-color: #000; }

.success {
  background: rgba(0, 156, 109, 0.05); }
  .success .withBorder {
    border-color: #90e8ce; }

.spacingTop {
  margin-top: 12px; }

.spacingBottom {
  margin-bottom: 32px; }

.center {
  text-align: center; }
